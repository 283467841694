<template>
  <b-container>
    <b-card bg-variant="light" class="mb-3">
      <b-card-title class="text-center mb-5" :title="$t('contract.link')" />
      <GoldflamTable
        :new-item="newContractObject"
        :all-items="filteredContracts"
        :edit-function="editContractFunction"
        :delete-function="deleteContract"
        :table-fields="tableFields"
      >
        <template v-slot:filters>
          <b-col>
            <b-select
              id="currentContractsFilter"
              v-model="filter.currentContracts"
              :options="currentContractsOptions"
            />
          </b-col>
          <b-col>
            <b-select id="userFilter" v-model="filter.user" :options="userOptions">
              <template #first>
                <b-form-select-option value="ALL">{{ $t('general.all-users') }}</b-form-select-option>
              </template>
            </b-select>
          </b-col>
          <b-col>
            <Datepicker
              id="datepickerSince"
              v-model="filter.since"
              :locale="$store.getters.getLocale"
              :placeholder="$t('contract.since')"
            />
          </b-col>
          <b-col>
            <Datepicker
              id="datepickerUntil"
              v-model="filter.until"
              :locale="$store.getters.getLocale"
              :placeholder="$t('contract.until')"
              :min="filter.since"
            />
          </b-col>
        </template>
      </GoldflamTable>

      <b-modal
        id="contractEditModal"
        v-model="modalControl.edit.show"
        size="lg"
        centered
        :title="$t('contract.editContract')"
        :ok-title="$t('general.save')"
        :cancel-title="$t('general.cancel')"
        cancel-variant="outline-secondary"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        @ok="saveContract()"
        :ok-disabled="$v.$invalid && !internalHourlyRatesFilled()"
        @hidden="resetEditModal"
      >
        <b-form>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editUser"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.user')"
          >
            <b-select
              id="editUser"
              :options="userOptions"
              v-model="$v.editContract.user.$model"
              :state="validateFormInput('user')"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editContractStart"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.start')"
          >
            <Datepicker
              id="editContractStart"
              v-model="$v.editContract.start.$model"
              :locale="this.$store.getters.getLocale"
              :placeholder="this.$t('datetime.start-date')"
              :reset-button="false"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editContractEnd"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.end')"
          >
            <b-input-group>
              <Datepicker
                id="editContractEnd"
                v-model="editContract.end"
                :locale="this.$store.getters.getLocale"
                :placeholder="this.$t('datetime.end-date')"
                :state="isTimeRangeValid(editContract)"
              />
              <b-input-group-append is-text>
                <b-form-checkbox
                  switch
                  class="mr-1"
                  :checked="editContract.end === null"
                  @change="editContract.end = null"
                >
                  {{ $t('contract.indefinite') }}
                </b-form-checkbox>
              </b-input-group-append>
              <b-form-invalid-feedback>
                {{ $t('contract.invalidFeedback-msg-endDate') }}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editContractHoursPerWeek"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('contract.workingHours')"
          >
            <b-input-group :append="$t('contract.perWeek')">
              <b-input
                id="editContractHoursPerWeek"
                type="text"
                v-model="$v.editContract.hoursPerWeek.$model"
                :state="validateFormInput('hoursPerWeek')"
              />
            </b-input-group>
            <b-form-invalid-feedback>
              {{ $t('contract.invalidFeedback-msg-hours') }} {{ defaultWorkHoursPerWeek }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editVacationEntitlementInDaysPerYear"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('contract.vacationEntitlement')"
          >
            <b-input-group :append="$t('contract.inDaysPerYear')">
              <b-input
                id="editVacationEntitlementInDaysPerYear"
                type="text"
                v-model="$v.editContract.vacationEntitlementInDaysPerYear.$model"
                :state="validateFormInput('vacationEntitlementInDaysPerYear')"
              />
            </b-input-group>
            <b-form-invalid-feedback>
              {{ $t('contract.invalidFeedback-msg-hours') }} {{ defaultWorkHoursPerWeek }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="federalStateSelection"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('federalState.federalState')"
          >
            <b-form-select
              id="federalStateSelection"
              :options="federalStateOptions"
              v-model="$v.editContract.federalState.$model"
              :state="validateFormInput('federalState')"
            >
              <template #first>
                <b-form-select-option :value="null" disabled selected>{{
                  $t('federalState.choose')
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="hourly-rate-table"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('contract.hourlyRate')"
          >
            <b-table
              class="mt-1"
              id="hourly-rate-table"
              small
              head-variant="light"
              :fields="hourlyRateTableFields"
              :items="editContract.internalHourlyRates"
            >
              <template #table-colgroup="scope">
                <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
              </template>
              <template v-slot:cell(hourlyRate)="row">
                <currency-input
                  :value="row.item.hourlyRateInCents"
                  :locale="$store.getters.getLocale"
                  @change="row.item.hourlyRateInCents = $event"
                  size="sm"
                />
              </template>
              <template v-slot:cell(start)="row">
                <Datepicker
                  :id="'editHourlyRateStart-' + row.index"
                  class="review-input"
                  v-model="row.item.start"
                  :locale="$store.getters.getLocale"
                  :placeholder="$t('datetime.start-date')"
                  :reset-button="false"
                  @input="updateEndDate(row)"
                  size="sm"
                />
              </template>
              <template v-slot:cell(end)="row">
                <Datepicker
                  :id="'editHourlyRateEnd-' + row.index"
                  class="review-input"
                  v-model="row.item.end"
                  :locale="$store.getters.getLocale"
                  :placeholder="$t('datetime.end-date')"
                  :disable-dates-after="row.item.start"
                  :disable-dates-before="row.item.start"
                  :reset-button="true"
                  size="sm"
                />
              </template>
              <template v-slot:cell(crudActions)="row">
                <b-button @click="deleteInternalHourlyRate(row.index)" size="xs" variant="outline-danger" class="mt-1">
                  <b-icon icon="trash" aria-hidden="true" />
                </b-button>
              </template>
            </b-table>
            <b-button @click="addInternalHourlyRate" variant="info" size="sm">{{
              $t('contract.addHourlyRate')
            }}</b-button>
          </b-form-group>
        </b-form>
      </b-modal>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import GoldflamTable from '@/components/GoldflamTable'
import Datepicker from '@/components/GoldflamDatepicker'
import { LocalDate } from '@/util/LocalDateTimeFormatter'
import { validationMixin } from 'vuelidate'
import { required, integer, minValue, maxValue } from 'vuelidate/lib/validators'
import { UserUtil } from '@/util/UserUtil'
import { StaticSelectOpts } from '@/constants/StaticSelectOpts'
import { cloneDeep as _cloneDeep } from 'lodash'
import CurrencyInput from '@/components/CurrencyInput'

export default {
  name: 'ManageContracts',
  components: { Datepicker, GoldflamTable, CurrencyInput },
  mixins: [ApiMixin, NotificationMixin, validationMixin],

  data() {
    return {
      objectModels: {
        contract: {
          user: {},
          start: null,
          end: null,
          hoursPerWeek: 0,
          federalState: '',
          vacationEntitlementInDaysPerYear: 0,
          internalHourlyRates: []
        },
        internalHourlyRate: {
          hourlyRateInCents: 0,
          start: null,
          end: null
        }
      },
      editContract: {},
      datePickerListener: null,
      allContracts: [],
      tableFields: [
        { key: 'user.fullName', label: this.$t('profile.name'), sortable: true },
        {
          key: 'start',
          label: this.$t('general.start'),
          sortable: true,
          formatter: 'formatDate',
          class: 'text-center'
        },
        { key: 'end', label: this.$t('general.end'), sortable: true, formatter: 'formatDate', class: 'text-center' },
        {
          key: 'hoursPerWeek',
          label: this.$t('contract.workingHours'),
          class: 'text-center',
          formatter: 'formatHours'
        },
        {
          key: 'percentage',
          label: this.$t('contract.percentage'),
          formatter: 'formatPercentage',
          class: 'text-center'
        },
        {
          key: 'federalState',
          label: this.$t('federalState.federalState'),
          class: 'text-center',
          formatter: 'formatFederalState'
        },
        {
          key: 'vacationEntitlementInDaysPerYear',
          label: this.$t('contract.vacationEntitlement'),
          class: 'text-center',
          formatter: 'formatDays'
        },
        { key: 'crudActions', label: this.$t('general.table.actions'), width: '80px' }
      ],
      hourlyRateTableFields: [
        {
          key: 'hourlyRate',
          label: this.$t('contract.hourlyRate'),
          sortable: false,
          class: 'text-center',
          width: '180px'
        },
        {
          key: 'start',
          label: this.$t('general.start'),
          sortable: true,
          formatter: 'formatDate',
          class: 'text-center'
        },
        { key: 'end', label: this.$t('general.end'), sortable: true, formatter: 'formatDate', class: 'text-center' },
        {
          key: 'crudActions',
          label: this.$t('general.table.actions'),
          class: 'text-center',
          width: '80px'
        }
      ],
      roleOptions: StaticSelectOpts.userRoleOptions,
      federalStateOptions: StaticSelectOpts.federalStateOptions,
      userList: [],
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      currentContractsOptions: [
        { value: 'ALL', text: this.$t('contract.all-contracts') },
        { value: 'CURRENT', text: this.$t('contract.current-contracts') }
      ],
      modalControl: {
        edit: {
          show: false
        },
        delete: {
          show: false
        }
      },
      filter: {
        user: 'ALL',
        since: null,
        until: null,
        currentContracts: 'CURRENT'
      }
    }
  },
  validations: {
    editContract: {
      user: {
        required
      },
      start: {
        required
      },
      hoursPerWeek: {
        required,
        integer,
        minValue: minValue(1),
        maxValue: maxValue(40)
      },
      federalState: {
        required
      },
      vacationEntitlementInDaysPerYear: {
        required,
        integer
      }
    }
  },
  created() {
    this.editContract = _cloneDeep(this.objectModels.contract)
  },
  mounted() {
    this.getAllUsers()
    this.loadContractList()
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser
    },
    defaultVacationEntitlementInDaysPerYear() {
      return this.$store.getters.getOrganisation.defaultVacationEntitlementInDaysPerYear
    },
    defaultWorkHoursPerWeek() {
      return this.$store.getters.getOrganisation.defaultWorkHoursPerWeek
    },
    userOptions() {
      return UserUtil.getSelectOptsForUserListGroupedByStatus(this.userList)
    },
    filteredContracts() {
      let contracts = this.allContracts
      if (this.filter.user !== 'ALL') {
        contracts = contracts.filter(c => {
          return c.user.userName === this.filter.user.userName
        })
      }
      if (this.filter.since !== null && this.filter.since !== '') {
        contracts = contracts.filter(c => {
          return c.start >= this.filter.since
        })
      }
      if (this.filter.until !== null && this.filter.until !== '') {
        contracts = contracts.filter(c => {
          return c.end <= this.filter.until
        })
      }
      if (this.filter.currentContracts !== null && this.filter.currentContracts === 'CURRENT') {
        contracts = contracts.filter(c => {
          return c.start <= LocalDate.today() && (c.end === null || c.end >= LocalDate.today())
        })
      }
      return contracts
    },
    newContractObject() {
      let newContract = _cloneDeep(this.objectModels.contract)
      newContract.start = LocalDate.today()
      newContract.vacationEntitlementInDaysPerYear = this.defaultVacationEntitlementInDaysPerYear
      newContract.hoursPerWeek = this.defaultWorkHoursPerWeek
      newContract.internalHourlyRates = []
      return newContract
    }
  },
  methods: {
    sortedHourlyRates() {
      if (this.editContract.internalHourlyRates) {
        return this.editContract.internalHourlyRates.sort((rate1, rate2) => {
          return new Date(rate1.start) - new Date(rate2.start)
        })
      }
      return []
    },
    validateFormInput(prop) {
      const { $dirty, $invalid } = this.$v.editContract[prop]
      return $dirty ? !$invalid : null
    },
    getAllUsers() {
      let self = this
      this.getRequest(
        '/users',
        new RequestConfig().onSuccess(res => {
          self.userList = res.data
        })
      )
    },
    loadContractList() {
      let self = this
      this.getRequest(
        '/contracts',
        new RequestConfig().onSuccess(res => {
          self.allContracts = res.data
        })
      )
    },
    isTimeRangeValid(myContract) {
      let isContractStartDefined = myContract.start !== null
      let isContractStartBeforeEnd = myContract.end === null || new Date(myContract.start) < new Date(myContract.end)
      return isContractStartDefined && isContractStartBeforeEnd ? null : false
    },
    deleteContract(contract) {
      let self = this
      const id = contract.id
      this.deleteRequest(
        `/contracts/${id}`,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(this.$t('contract.delete-success-msg'))
          self.loadContractList()
        })
      )
    },
    editContractFunction(contract) {
      this.editContract = _cloneDeep(contract)
      this.modalControl.edit.show = true
      this.editContract.internalHourlyRates = this.sortedHourlyRates()
    },
    saveContract() {
      let url = '/contracts'
      const id = this.editContract.id
      if (this.editContract.id) {
        url += `/${id}`
      }
      let self = this
      this.postRequest(
        url,
        JSON.stringify(this.editContract),
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('contract.contract-success-msg'))
          self.loadContractList()
        })
      )
    },
    resetEditModal() {
      this.modalControl.edit.show = false
      this.editContract = _cloneDeep(this.objectModels.contract)
      this.$v.$reset()
    },
    addInternalHourlyRate() {
      if (this.editContract.internalHourlyRates === null) {
        this.editContract.internalHourlyRates = []
      }
      this.editContract.internalHourlyRates.push(_cloneDeep(this.objectModels.internalHourlyRate))
    },
    deleteInternalHourlyRate(index) {
      this.editContract.internalHourlyRates.splice(index, 1)
    },
    updateEndDate(row) {
      const index = row.index
      const start = row.item.start
      const end = row.item.end
      let isStartDefined = start !== null
      let isEndDefined = end !== null
      if (isStartDefined && isEndDefined) {
        if (new Date(start) > new Date(end)) {
          this.editContract.internalHourlyRates[index].end = start
        }
      }
    },
    internalHourlyRatesFilled() {
      let isFilled = true
      this.editContract.internalHourlyRates.forEach(hourlyRate => {
        isFilled =
          isFilled && hourlyRate.hourlyRateInCents !== null && hourlyRate.start !== null && hourlyRate.end !== null
      })
      return isFilled
    }
  }
}
</script>

<style scoped lang="scss">
input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.review-input {
  text-align: center;
  width: 100% !important;
  font-size: initial;
  background-color: transparent;
  border-color: #dddddd;
  transition: 0.2s;

  &:hover {
    color: black;
    background: white;
  }
  &:focus {
    color: black;
    background: white;
  }
}
</style>
